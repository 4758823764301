import { template as template_9811466a4ed94472be57bfdcb0093b6d } from "@ember/template-compiler";
const FKText = template_9811466a4ed94472be57bfdcb0093b6d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
