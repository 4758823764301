import { template as template_b97aa54f22ad416f98fe3d4d462a72de } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_b97aa54f22ad416f98fe3d4d462a72de(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_b97aa54f22ad416f98fe3d4d462a72de(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_b97aa54f22ad416f98fe3d4d462a72de(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;
