import { template as template_b8010a209f5e4b4682734d3b9ba8ff3d } from "@ember/template-compiler";
const FKLabel = template_b8010a209f5e4b4682734d3b9ba8ff3d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
