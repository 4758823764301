import { template as template_0345bea19afa4a2db6f07d691ca0b0f0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0345bea19afa4a2db6f07d691ca0b0f0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
