import { template as template_9f656ed1a19647609eb2235a79a39e7d } from "@ember/template-compiler";
const WelcomeHeader = template_9f656ed1a19647609eb2235a79a39e7d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
